import React, { useEffect, useState, useRef } from "react";

function ToggleSwitch({ id, checked, onChange, status }) {
  const [circleSize, setCircleSize] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const size = containerRef.current.getBoundingClientRect().height - 4;
    setCircleSize(size);
  }, []);

  const handleToggle = () => {
    ///setIsOn(!isOn);
    onChange && onChange(!status);
  };

  return (
    <label
      htmlFor={id}
      className="flex items-center justify-center cursor-pointer"
    >
      <div
        className={`relative w-16 h-10 ${
          status ? "bg-green-400" : "bg-red-500"
        } rounded-full shadow-lg`}
        style={{
          boxShadow: status
            ? "4px 4px 8px rgba(0, 0, 0, 0.4), -4px -4px 8px rgba(255, 255, 255, 0.2)"
            : "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
        }}
        ref={containerRef}
      >
        <div
          className={`absolute top-1/2 transform -translate-y-1/2 w-8 h-8 ${
            status ? "right-1" : "left-1"
          } bg-white rounded-full transition-transform duration-300 ${
            status ? "translate-x-full" : ""
          }`}
          style={{
            boxShadow: status
              ? "-2px -2px 6px rgba(255, 255, 255, 0.5), 2px 2px 6px rgba(0, 0, 0, 0.2)"
              : "-2px -2px 6px rgba(255, 255, 255, 0.2), 2px 2px 6px rgba(0, 0, 0, 0.5)",
            right: status ? circleSize : undefined,
          }}
        />
      </div>
      <input
        id={id}
        type="checkbox"
        className="hidden"
        checked={status}
        //onChange={handleToggle}
        onChange={(status) => handleToggle("switch1", status)}
      />

      <span
        className={`${
          status ? "text-green-500" : "text-red-500"
        } ml-2 text-sm font-medium`}
      >
        {status ? "ON" : "OFF"}
      </span>
    </label>
  );
}

export default ToggleSwitch;
