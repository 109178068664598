import Button from "./Button";
import Design from "./Design";
import Main from "./Main";

function App() {
  return (
    <div>
      {/* <h1 className="text-3xl font-bold underline">Hello world!</h1> */}
      {/* <Main /> */}

      {/* <Button /> */}
      <Design />
    </div>
  );
}

export default App;
