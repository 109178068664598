import { useState, useRef, useEffect } from "react";
import ToggleSwitch from "./ToogleSwitch";

const imeiArr = JSON.parse(localStorage.getItem("imeiArr")) || [];
console.log(imeiArr);

function Button() {
  const imeiArr = JSON.parse(localStorage.getItem("imeiArr")) || [];
  // console.log(imeiArr);
  let sendmsg = eval(`(${localStorage.getItem("sendmsg")})`);
  //   let topic = "vidani/sl/864180052836397";
  let topicCabin1 = `vidani/ss/${imeiArr[1]}`;
  let topicCabin2 = `vidani/ss/${imeiArr[2]}`;
  let topicCabin3 = `vidani/ss/${imeiArr[3]}`;
  let topic = "vidani/ss/vio8c4b14496264";
  let msgon = { type: "control", id: 1, key: 1, value: 1 };
  let msgoff = { type: "control", id: 1, key: 1, value: 0 };

  let msgon2 = { type: "control", id: 1, key: 2, value: 1 };
  let msgoff2 = { type: "control", id: 1, key: 2, value: 0 };

  // console.log(topic);
  // console.log(topicCabin1);
  // console.log(topicCabin2);
  // console.log(topicCabin3);

  const [isOn, setIsOn] = useState(false);
  const [isOn2, setIsOn2] = useState(false);

  setInterval(() => {
    const dataArr = JSON.parse(localStorage.getItem("dataArr")) || [];
    setIsOn(dataArr[0]?.[0] === 48 ? false : true);
    setIsOn2(dataArr[0]?.[1] === 48 ? false : true);
  }, 500);

  useEffect(() => {
    const dataArr = JSON.parse(localStorage.getItem("dataArr")) || [];
    setIsOn(dataArr[0]?.[0] === 48 ? false : true);
    setIsOn2(dataArr[0]?.[1] === 48 ? false : true);
  }, []);

  const handleToggle = (id, isOn) => {
    console.log(`Toggle switch ${id} is ${isOn ? "on" : "off"}`);
    const message = isOn ? msgon : msgoff;
    sendmsg(topic, message);

    const message2 = isOn2 ? msgon2 : msgoff2;
    sendmsg(topic, message2);
  };

  const handleToggle2 = (id, isOn2) => {
    console.log(`Toggle switch ${id} is ${isOn2 ? "on" : "off"}`);

    const message2 = isOn2 ? msgon2 : msgoff2;
    sendmsg(topic, message2);
  };

  return (
    <div className="flex flex-col gap-3">
      <ToggleSwitch
        id="switch1"
        checked={true}
        onChange={(isOn) => handleToggle("switch1", isOn)}
        status={isOn}
        onClick={handleToggle}
      />

      <ToggleSwitch
        id="switch2"
        checked={true}
        onChange={(isOn2) => handleToggle2("switch2", isOn2)}
        status={isOn2}
        onClick={handleToggle2}
      />

      {/* <ToggleSwitch
        id="switch2"
        checked={false}
        // onChange={(isOn) => handleToggle("switch2", isOn)}
      />
      {isOn ? "ON" : "OFF"}
      <ToggleSwitch
        id="switch3"
        checked={false}
        onChange={(isOn) => handleToggle("switch3", isOn)}
      />
      {isOn ? "ON" : "OFF"}
      <ToggleSwitch
        id="switch4"
        checked={true}
        onChange={(isOn) => handleToggle("switch4", isOn)}
      />
      {isOn ? "ON" : "OFF"}
      <ToggleSwitch
        id="switch5"
        checked={true}
        onChange={(isOn) => handleToggle("switch5", isOn)}
      />
      {isOn ? "ON" : "OFF"} */}
    </div>
  );
}

export default Button;
