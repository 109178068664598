import React, { useState, useEffect } from "react";

const Main = () => {
  let sendmsg = eval(`(${localStorage.getItem("sendmsg")})`);
  //   let topic = "vidani/sl/864180052836397";
  let topic = "vidani/ss/vio8c4b14496264";
  let topic2 = "vidani/ss/987654321054322";
  let msgon = { type: "control", id: 1, key: 1, value: 1 };
  let msgoff = { type: "control", id: 1, key: 1, value: 0 };

  let msgon2 = { type: "control", id: 1, key: 2, value: 1 };
  let msgoff2 = { type: "control", id: 1, key: 2, value: 0 };

  const [isOn, setIsOn] = useState(false);
  const [isOn2, setIsOn2] = useState(false);

  setInterval(() => {
    const dataArr = JSON.parse(localStorage.getItem("dataArr")) || [];
    setIsOn(dataArr[0]?.[0] === 48 ? false : true);
    setIsOn2(dataArr[0]?.[1] === 48 ? false : true);
  }, 500);

  useEffect(() => {
    const dataArr = JSON.parse(localStorage.getItem("dataArr")) || [];
    setIsOn(dataArr[0]?.[0] === 48 ? false : true);
    setIsOn2(dataArr[0]?.[1] === 48 ? false : true);
  }, []);

  const handleClick = () => {
    const message = isOn ? msgoff : msgon;
    sendmsg(topic, message);
    console.log("reached relay 1");
  };

  const handleClick2 = () => {
    const message2 = isOn2 ? msgoff2 : msgon2;
    console.log(message2);
    sendmsg(topic, message2);
    console.log("reached in relay 2");
  };

  return (
    <div>
      <button
        className={`mx-2 p-2 text-lg font-bold rounded-lg ${
          isOn ? "bg-green-500 text-white" : "bg-red-500 text-white"
        }`}
        onClick={handleClick}
      >
        {isOn ? "ON" : "OFF"}
      </button>

      <button
        className={`mx-2 p-2 text-lg font-bold rounded-lg ${
          isOn2 ? "bg-green-500 text-white" : "bg-red-500 text-white"
        }`}
        onClick={handleClick2}
      >
        {isOn2 ? "ON" : "OFF"}
      </button>

      {/* <button
        className={`mx-2 p-2 text-lg font-bold rounded-lg ${
          isOn ? "bg-green-500 text-white" : "bg-red-500 text-white"
        }`}
        onClick={handleMessageArrival}
      >
        {isOn ? "ON" : "OFF"}
      </button> */}
    </div>
  );
};

export default Main;
