import React, { useEffect, useState } from "react";
import ToggleSwitch from "./ToogleSwitch";

const Design = () => {
  //const imeiArr = JSON.parse(localStorage.getItem("imeiArr")) || [];
  // console.log(imeiArr);
  let sendmsg = eval(`(${localStorage.getItem("sendmsg")})`);
  //   let topic = "vidani/sl/864180052836397";
  //   let topicCabin1 = `vidani/ss/${imeiArr[1]}`;
  //   let topicCabin2 = `vidani/ss/${imeiArr[2]}`;
  //   let topicCabin3 = `vidani/ss/${imeiArr[3]}`;
  let topic = "vidani/ss/vio8c4b14496264";

  //on off commands for relay1
  let msgon = { type: "control", id: 1, key: 1, value: 1 };
  let msgoff = { type: "control", id: 1, key: 1, value: 0 };

  //on off commands for relay2
  let msgon2 = { type: "control", id: 1, key: 2, value: 1 };
  let msgoff2 = { type: "control", id: 1, key: 2, value: 0 };

  let topic2 = "vidani/ss/987654321054322";

  let topic3 = "vidani/ss/987654321054323";

  let topic4 = "vidani/ss/987654321054324";

  const [isOn, setIsOn] = useState(false);
  const [isOn2, setIsOn2] = useState(false);

  const [topic2IsOn1, setTopic2IsOn1] = useState(false);
  const [topic2IsOn2, setTopic2IsOn2] = useState(false);

  const [topic3IsOn1, setTopic3IsOn1] = useState(false);
  const [topic3IsOn2, setTopic3IsOn2] = useState(false);

  const [topic4IsOn1, setTopic4IsOn1] = useState(false);
  const [topic4IsOn2, setTopic4IsOn2] = useState(false);

  setInterval(() => {
    const dataArr = JSON.parse(localStorage.getItem("dataArr")) || [];
    setIsOn(dataArr[0]?.[0] === 48 ? false : true);
    setIsOn2(dataArr[0]?.[1] === 48 ? false : true);

    setTopic2IsOn1(dataArr[1]?.[0] === 48 ? false : true);
    setTopic2IsOn2(dataArr[1]?.[1] === 48 ? false : true);

    setTopic3IsOn1(dataArr[2]?.[0] === 48 ? false : true);
    setTopic3IsOn2(dataArr[2]?.[1] === 48 ? false : true);

    setTopic4IsOn1(dataArr[3]?.[0] === 48 ? false : true);
    setTopic4IsOn2(dataArr[3]?.[1] === 48 ? false : true);
  }, 500);

  useEffect(() => {
    const dataArr = JSON.parse(localStorage.getItem("dataArr")) || [];
    setIsOn(dataArr[0]?.[0] === 48 ? false : true);
    setIsOn2(dataArr[0]?.[1] === 48 ? false : true);

    setTopic2IsOn1(dataArr[1]?.[0] === 48 ? false : true);
    setTopic2IsOn2(dataArr[1]?.[1] === 48 ? false : true);

    setTopic3IsOn1(dataArr[2]?.[0] === 48 ? false : true);
    setTopic3IsOn2(dataArr[2]?.[1] === 48 ? false : true);

    setTopic4IsOn1(dataArr[3]?.[0] === 48 ? false : true);
    setTopic4IsOn2(dataArr[3]?.[1] === 48 ? false : true);
  }, []);

  const handleToggle = (id, isOn) => {
    console.log(`Toggle switch ${id} is ${isOn ? "on" : "off"}`);
    const message = isOn ? msgon : msgoff;
    sendmsg(topic, message);
  };

  const handleToggle2 = (id, isOn2) => {
    console.log(`Toggle switch ${id} is ${isOn2 ? "on" : "off"}`);
    const message2 = isOn2 ? msgon2 : msgoff2;
    sendmsg(topic, message2);
  };

  const handleToggleTopic2Relay1 = (id, topic2IsOn1) => {
    //console.log(`Toggle switch ${id} is ${isOn2 ? "on" : "off"}`);
    const messageTopic2R1 = topic2IsOn1 ? msgon : msgoff;
    sendmsg(topic2, messageTopic2R1);
  };

  const handleToggleTopic2Relay2 = (id, topic2IsOn2) => {
    const messageTopic2R2 = topic2IsOn2 ? msgon2 : msgoff2;
    sendmsg(topic2, messageTopic2R2);
  };

  const handleToggleTopic3Relay1 = (id, topic3IsOn1) => {
    const messageTopic3R1 = topic3IsOn1 ? msgon : msgoff;
    sendmsg(topic3, messageTopic3R1);
  };

  const handleToggleTopic3Relay2 = (id, topic3IsOn2) => {
    const messageTopic3R2 = topic3IsOn2 ? msgon2 : msgoff2;
    sendmsg(topic3, messageTopic3R2);
  };

  const handleToggleTopic4Relay1 = (id, topic4IsOn1) => {
    const messageTopic4R1 = topic4IsOn1 ? msgon : msgoff;
    sendmsg(topic4, messageTopic4R1);
  };

  const handleToggleTopic4Relay2 = (id, topic4IsOn2) => {
    const messageTopic4R2 = topic4IsOn2 ? msgon2 : msgoff2;
    sendmsg(topic4, messageTopic4R2);
  };

  return (
    <div className="bg-white rounded-lg shadow-xl p-4 w-4/5  m-auto mt-5 sm:w-1/2 lg:w-1/2 md:w-1/2 xl:w-2/5 sm:mt-10">
      {/* room 1 */}
      <div className="mt-4 rounded-lg p-4 bg-white shadow-inner">
        <div className="bg-gray-200 rounded-lg p-4 flex flex-wrap justify-between items-center">
          <span className="text-gray-700 font-bold text-center w-1/2 mx-auto sm:m-auto xl:m-0 md:m-0 lg:m-auto">
            Living Room
          </span>
          <div className="flex justify-center items-center flex-wrap mx-auto md:m-0 lg:m-0 ">
            <div className="flex-1 m-2 ">
              <ToggleSwitch
                id="switch1"
                checked={true}
                onChange={(isOn) => handleToggle("switch1", isOn)}
                status={isOn}
                onClick={handleToggle}
              />
            </div>
            <div className="flex-1 m-2 ">
              <ToggleSwitch
                id="switch2"
                checked={true}
                onChange={(isOn2) => handleToggle2("switch2", isOn2)}
                status={isOn2}
                onClick={handleToggle2}
              />
            </div>
          </div>
        </div>
      </div>
      {/* room 2 */}
      <div className="mt-4 rounded-lg p-4 bg-white shadow-inner">
        <div className="bg-gray-200 rounded-lg p-4 flex flex-wrap justify-between items-center">
          <span className="text-gray-700 font-bold text-center w-1/2 mx-auto sm:m-auto xl:m-0 md:m-0 lg:m-auto ">
            Bed Room
          </span>
          <div className="flex justify-center items-center flex-wrap mx-auto md:m-0 lg:m-0 ">
            <div className="flex-1 m-2 ">
              <ToggleSwitch
                id="switch3"
                checked={true}
                onChange={(topic2IsOn1) =>
                  handleToggleTopic2Relay1("switch3", topic2IsOn1)
                }
                status={topic2IsOn1}
                onClick={handleToggleTopic2Relay1}
              />
            </div>
            <div className="flex-1 m-2 ">
              <ToggleSwitch
                id="switch4"
                checked={true}
                onChange={(topic2IsOn2) =>
                  handleToggleTopic2Relay2("switch4", topic2IsOn2)
                }
                status={topic2IsOn2}
                onClick={handleToggleTopic2Relay2}
              />
            </div>
          </div>
        </div>
      </div>
      {/* room 3 */}
      <div className="mt-4 rounded-lg p-4 bg-white shadow-inner">
        <div className="bg-gray-200 rounded-lg p-4 flex flex-wrap justify-between items-center">
          <span className="text-gray-700 font-bold text-center w-1/2 mx-auto sm:m-auto xl:m-0 md:m-0 lg:m-auto">
            Children's Room
          </span>
          <div className="flex justify-center items-center flex-wrap mx-auto sm:m-auto xl:m-0 md:m-0 lg:m-auto">
            <div className="flex-1 m-2 ">
              <ToggleSwitch
                id="switch5"
                checked={true}
                onChange={(topic3IsOn1) =>
                  handleToggleTopic3Relay1("switch5", topic3IsOn1)
                }
                status={topic3IsOn1}
                onClick={handleToggleTopic3Relay1}
              />
            </div>
            <div className="flex-1 m-2">
              <ToggleSwitch
                id="switch6"
                checked={true}
                onChange={(topic3IsOn2) =>
                  handleToggleTopic3Relay2("switch6", topic3IsOn2)
                }
                status={topic3IsOn2}
                onClick={handleToggleTopic3Relay2}
              />
            </div>
          </div>
        </div>
      </div>
      {/* room 4 */}
      <div className="mt-4 rounded-lg p-4 bg-white shadow-inner">
        <div className="bg-gray-200 rounded-lg p-4 flex flex-wrap justify-between items-center">
          <span className="text-gray-700 font-bold text-center w-1/2 mx-auto sm:m-auto xl:m-0 md:m-0 lg:m-auto">
            Kitchen
          </span>
          <div className="flex justify-center items-center flex-wrap mx-auto md:m-0 lg:m-0 ">
            <div className="flex-1 m-2 ">
              <ToggleSwitch
                id="switch7"
                checked={true}
                onChange={(topic4IsOn1) =>
                  handleToggleTopic4Relay1("switch7", topic4IsOn1)
                }
                status={topic4IsOn1}
                onClick={handleToggleTopic4Relay1}
              />
            </div>
            <div className="flex-1 m-2 ">
              <ToggleSwitch
                id="switch8"
                checked={true}
                onChange={(topic4IsOn2) =>
                  handleToggleTopic4Relay2("switch8", topic4IsOn2)
                }
                status={topic4IsOn2}
                onClick={handleToggleTopic4Relay2}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Design;
